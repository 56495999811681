import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"
import { RichText } from "prismic-reactjs"
import Image from "../components/image"
import { css } from "@emotion/core"
import { colors } from "../styles/styles"
import SEO from "../components/SEO"
import Hero from "../components/hero"
import Feedback from "../components/feedback"
import { ArrowScrollToTop } from "../components/arrowScrollToTop"
import './../styles/fieldpage/fieldpage.scss'

const FieldsPage = ({ data, pageContext: { locale }, location }) => {
  const FieldsPageData = data.prismic.fieldspage
  return (
    <>
      <SEO
        title={FieldsPageData.meta_title}
        desc={FieldsPageData.meta_description}
        metadata={data.site.siteMetadata}
        locale={locale}
        location={location}
        pathname={location.pathname}
      />
      <Hero
        hero={{
          title: FieldsPageData.title,
          subtitle: FieldsPageData.subtitle,
          image: {
            image: FieldsPageData.main_image,
            sharp: FieldsPageData.main_imageSharp,
          },
        }}
      />
      <section className="section is-medium" css={fieldsPageCss}>
        <div className="container">
          <div className="columns is-multiline is-centered">
            {FieldsPageData.body.map((field, i) => (
              <div
                key={i}
                className="column is-full-mobile is-half-tablet is-half-desktop"
              >
                <div className="shape">
                  <svg viewBox="0 0 406 682" xmlns="http://www.w3.org/2000/svg">
                    <defs>
                      <linearGradient
                        id="linearGradientOrange"
                        y2="50%"
                        x2="0%"
                        y1="50%"
                        x1="100%"
                      >
                        <stop offset="0%" stopColor="#FD7543" />
                        <stop offset="100%" stopColor="#DE4E4E" />
                      </linearGradient>
                    </defs>
                    <g
                      stroke="none"
                      strokeWidth="1"
                      fill="none"
                      fillRule="evenodd"
                    >
                      <g
                        transform="translate(-517.000000, -530.000000)"
                        stroke="#FFF"
                        strokeWidth="1.8"
                      >
                        <g transform="translate(112.000000, 530.000000)">
                          <g transform="translate(405.000000, 0.000000)">
                            <path
                              d="M186.782293,4.26064845 C197.126236,-0.220247553 208.873764,-0.220247553 219.217707,4.26064845 L219.217707,4.26064845 L388.975545,89.7571209 C398.460049,95.245608 404.524765,105.131258 405.1,116.043373 L405.1,116.043373 L405.1,565.960725 C404.5269,576.873558 398.464504,586.761184 388.981242,592.253001 L388.981242,592.253001 L219.414787,677.737534 C209.073516,682.220901 197.326829,682.220853 186.985589,677.73739 L186.985589,677.73739 L17.0300286,592.228798 C7.54202086,586.746061 1.47417527,576.861067 0.9,565.948764 L0.9,565.948764 L0.9,116.043373 C1.47523535,105.131258 7.53995101,95.245608 17.0244547,89.7571209 L17.0244547,89.7571209 Z"
                              id="Mask-Copy-5"
                            ></path>
                          </g>
                        </g>
                      </g>
                    </g>
                    <path
                      id="Selected-Color"
                      d="M186.782293,4.26064845 C197.126236,-0.220247553 208.873764,-0.220247553 219.217707,4.26064845 L219.217707,4.26064845 L388.975545,89.7571209 C398.460049,95.245608 404.524765,105.131258 405.1,116.043373 L405.1,116.043373 L405.1,565.960725 C404.5269,576.873558 398.464504,586.761184 388.981242,592.253001 L388.981242,592.253001 L219.414787,677.737534 C209.073516,682.220901 197.326829,682.220853 186.985589,677.73739 L186.985589,677.73739 L17.0300286,592.228798 C7.54202086,586.746061 1.47417527,576.861067 0.9,565.948764 L0.9,565.948764 L0.9,116.043373 C1.47523535,105.131258 7.53995101,95.245608 17.0244547,89.7571209 L17.0244547,89.7571209 Z"
                    />
                  </svg>
                  <article className="container shape__container">
                    {field.primary.field_image && (
                      <Image
                        sharp={field.primary.field_imageSharp}
                        image={field.primary.field_image}
                      />
                    )}
                    <div className="content">
                      <h3 className="title is-5 is-size-6-mobile has-text-centered is-uppercase has-text-weight-semibold">
                        {field.primary.field_title &&
                          RichText.asText(field.primary.field_title)}
                      </h3>
                      <p className="is-size-5-desktop is-size-6-tablet is-size-7-mobile has-text-weight-light">
                        {field.primary.field_subtitle &&
                          RichText.asText(field.primary.field_subtitle)}
                      </p>
                    </div>
                    <div className="columns is-multiline is-centered is-mobile brands" css={{display: "flex", justifyContent: "center", gap: "40px", flexWrap: "wrap"}}>
                      {field.fields.map((brand, y) => {
                        return (
                          <div key={y} className="column is-one-third" css={{scale: "1.3"}}>
                            <figure className="image is-margin-0-auto clients_logo">
                              {brand.field_brand_image &&
                              brand.field_brand_link ? (
                                <a
                                  href={brand.field_brand_link.url}
                                  rel="noopener noreferrer nofollow"
                                  target="_blank"
                                >
                                  <Image
                                    sharp={brand.field_brand_imageSharp}
                                    image={brand.field_brand_image}
                                  />
                                </a>
                              ) : (
                                <Image
                                  sharp={brand.field_brand_imageSharp}
                                  image={brand.field_brand_image}
                                />
                              )}
                            </figure>
                          </div>
                        )
                      })}
                    </div>
                  </article>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <Feedback feedback={data.prismic.allFeedbackcomponents.edges[0].node} />
      <ArrowScrollToTop />
    </>
  )
}

export default FieldsPage

FieldsPage.propTypes = {
  data: PropTypes.shape({
    prismic: PropTypes.object.isRequired,
  }).isRequired,
}

export const fieldsQuery = graphql`
  query FieldsQuery($uid: String!, $locale: String!) {
    prismic {
      fieldspage(uid: $uid, lang: $locale) {
        meta_title
        meta_description
        title
        subtitle
        main_image
        ...Sectors
      }
      ...Feedback
    }
    site {
      ...SiteInformation
    }
  }
`

const fieldsPageCss = css`
  .shape {

    margin: 0 auto;

    position: relative;
    text-align: center;
    path {
      fill: #fff;
      stroke: url(#linearGradientOrange);

    }
  }
  .shape__container {
    padding: 30px 20px;
    width: 100%;
    position: absolute;
    top: 20px;
    left: 50%;
    text-align: center;
    transform: translateX(-50%);
    & > .gatsby-image-wrapper {
      height: 225px;
    }
    h3 {
    }
    p {
      color: ${colors.blueLightSeven};
    }
  }

  .brands {
    .gatsby-image-wrapper {
      max-height: 40px;
      width: auto;
    }
    .column {
      max-height: 55px;
    }
  }

  @media (max-width: 398px) {
    .shape__container {
      padding: 5px 10px;
      top: 15px;
      h3 {
        margin-bottom: 1rem;
      }

      & > .content {
        margin-bottom: 1rem;
      }
      & > .gatsby-image-wrapper {
        height: 120px;
      }
    }
  }

  @media (min-wdith: 399px) and (max-width: 767px) {
    .shape {
      .shape__container {
        /* max-width: 400px; */
        & > .gatsby-image-wrapper {
          height: 170px;
        }
      }
    }
  }


  @media (max-width: 767px) {
    .shape {
      .shape__container {
        max-width: 400px;
        /* & > .gatsby-image-wrapper {
          height: 170px;
        } */
      }
      svg {
        max-height: 650px;
      }
      .brands {
        .gatsby-image-wrapper {
          max-height: 25px;
        }
        .column {
          max-height: 35px;
        }
      }
    }
  }


  @media screen and (min-width: 768px) and (max-width: 992px) {
    .column.is-half-tablet {
      width: 60%;
      margin: 0 auto;
    }
  }

  @media (min-width: 1024px) {
    .shape {
      &:hover {
        animation-duration: 1s;
        animation-name: slideTop;
        path {
          stroke: url(#linearGradientOrange);
        }
      }
    }
  }

  @keyframes slideTop {
    from {
      top: 0%;
    }
    to {
      top: -2%;
    }
  }

  @media (min-width: 1024px) {
    .shape {
      max-width: 580px;
    }
  }

  @media (min-width: 1024px) and (max-width: 1215px) {

    .shape__container {
      & > .gatsby-image-wrapper {
        height: 150px;
      }
    }
  }
  @media (min-width: 1408px) {
    .shape__container {
      & > .gatsby-image-wrapper {
        height: 325px;
      }
    }
  }
`
